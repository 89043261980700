import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import icon1 from '../../images/icon/c_user.svg';
import icon2 from '../../images/icon/c_mail.svg';
import icon3 from '../../images/icon/c_select.svg';
import icon4 from '../../images/icon/c_call.svg';
import icon5 from '../../images/icon/c_message.svg';
import axios from 'axios';

const ContactForm = (props) => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [submitted, setSubmitted] = useState(false);
    
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async e => {
        e.preventDefault();
        if (validator.allValid()) {
            try {
                const formEndpoint = 'https://getform.io/f/ajjerrva'; 
                await axios.post(formEndpoint, forms);
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: ''
                });
                setSubmitted(true);
                validator.hideMessages();
            } catch (error) {
                console.error('Form submission error:', error);
            }
        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="xb-item--form contact-from" onSubmit={submitHandler}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon1} alt="" /></span>
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Enter your name"
                             />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon2} alt="" /></span>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Enter your email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon3} alt="" /></span>
                        <select
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            value={forms.subject}
                            type="text"
                            className="form-control"
                            name="subject">
                            <option value="">Select a Visa type</option>
                            <option value="Student Visa">Student Visa</option>
                            <option value="Tourist Visa">Tourist Visa</option>
                            <option value="Commercial Visa">Family Visa</option>
                            <option value="Residence Visa">Permanent Visa</option>
                            <option value="Working Visa">Work Visa</option>
                        </select>
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="xb-item--field">
                        <span><img src={icon4} alt="" /></span>
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="+00 123 4567 89" />
                        {/* {validator.message('phone', forms.phone, 'required|phone')} */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="xb-item--field">
                        <span><img src={icon5} alt="" /></span>
                        <textarea
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                            placeholder="Write Your Message...">
                        </textarea>
                        {validator.message('message', forms.message, 'required')}
                    </div>
                </div>
                <div className="col-12">
                    <button className="thm-btn" type="submit">Send Message</button>
                </div>
                {submitted && (
                    <div className="col-12">
                        <p className="successMessage">Your message has been sent successfully!</p>
                    </div>
                )}
            </div>
        </form>
    )
}

export default ContactForm;
