import swIcon1 from '../images/icon/sv_01.svg'
import swIcon2 from '../images/icon/sv_02.svg'
import swIcon3 from '../images/icon/sv_03.svg'
import swIcon4 from '../images/icon/sv_04.svg'
import swIcon5 from '../images/icon/sv_05.svg'
import swIcon6 from '../images/icon/s_icon01.svg'
import swIcon7 from '../images/icon/s_icon02.svg'
import swIcon8 from '../images/icon/s_icon03.svg'
import swIcon9 from '../images/icon/s_icon04.svg'
import swIcon10 from '../images/icon/s_icon05.svg'
import swIcon11 from '../images/icon/s_icon06.svg'
import swIcon12 from '../images/icon/s_icon07.svg'
import swIcon13 from '../images/icon/s_icon08.svg'



const Services = [
    {
        Id: '2',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Visa Services',
        slug: 'Visa Services',
        description:'',
    },
    {
        Id: '1',
        sIcon:swIcon1,
        title: 'Flight Tickets',
        slug: 'Flight Tickets',
        description:'',
    },
   
    
    {
        Id: '3',
        sIcon:swIcon4,
        iconColor:'color3',
        title: 'Hotel Bookings',
        slug: 'Hotel Bookings',
        offset: 'offset-lg-4',
        description:'',
    },
    {
        Id: '4',
        
        iconColor:'',
        title: '',
        slug: '',
        offset: '',
        description:'',
    },
    {
        Id: '11',
        sIcon:swIcon3,
        iconColor:'color4',
        title: 'Permanent Visa',
        slug: 'Permanent-Visa',
        description:'Expert Guidance for a Seamless Immigration Journey Expert Guidance...',
    },
    {
        Id: '5',
        sIcon:swIcon5,
        iconColor:'color5',
        title: 'Work Visa',
        slug: 'Work-Visa',
        description:'Get your Visa now for new business and earning opportunities. We deliver your...',
    },
    {
        Id: '6',
        sIcon:swIcon13,
        iconColor:'color3',
        title: 'Flights & Hotel Reservation',
        slug: 'Flights-Reservation',
        description:'Offering best deals on flights and hotel reservation.',
    },
    {
        Id: '7',
        sIcon:swIcon6,
        iconColor:'color2',
        title: 'Career Counseling',
        slug: 'Career-Counseling',
        description:'Assessment of eligibility for different student visa..',
    },
    {
        Id: '8',
        sIcon:swIcon7,
        title: 'Visa \n Guidance',
        slug: 'Visa-Guidance',
        description:'Offering information about visa requirements, procedures..',
    },
    {
        Id: '9',
        sIcon:swIcon8,
        title: 'Financial Planning',
        slug: 'Financial-Planning',
        description:'Advising on demonstrating sufficient financial..',
    },
    {
        Id: '10',
        sIcon:swIcon9,
        title: 'Application Assistance',
        slug: 'Application-Assistance',
        description:'Assisting with compiling required documents..',
    },
    {
        Id: '11',
        sIcon:swIcon10,
        title: 'Document Preparation',
        slug: 'Document-Preparation',
        description:'Assisting in gathering such as organizing necessary..',
    },
    {
        Id: '12',
        sIcon:swIcon11,
        title: 'Health Insurance Guidance',
        slug: 'Health-Insurance-Guidance',
        description:'Offering information about housing orientation, and other..',
    },
    {
        Id: '13',
        sIcon:swIcon12,
        title: 'Language Proficiency Tests',
        slug: 'Language-Proficiency-Tests',
        description:'Providing guidance on language proficiency tests required..',
    },
    {
        Id: '14',
        sIcon:swIcon13,
        title: 'Visa Submission and Tracking',
        slug: 'Visa-Submission-and-Tracking',
        description:'Assisting in submitting the visa application to the..',
    },


]    

export default Services;