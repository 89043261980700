import React, { useState } from "react";
// import fImg1 from '../../images/faq/faq_img.png'
import fImg1 from "../../images/manzilTravel/visa-faq.jpeg";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="xb-faq-content">
              <div className="sec-title mb-125">
                <h2 className="mb-30 wow skewIn">
                  Common questions <br /> <span> answered</span>
                </h2>
                <p>
                  At the heart of our commitment to providing <br /> exceptional
                  immigration solutions stands our trusted
                </p>
              </div>
              <div className="faq-img">
                <img src={fImg1} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="xb-faq">
              <Accordion open={open} toggle={toggle} className="accordion_box">
                <AccordionItem className="block">
                  <AccordionHeader targetId="1" className="acc-btn">
                    Is there an additional fee for early appointments?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="1" className="acc_body">
                    <div className="content">
                      <p>
                        No, we do not charge an additional fee for early
                        appointments.
                      </p>
                      {/* <ul>
                                                <li><i className="far fa-check"></i>Comprehensive Visa Assistance</li>
                                                <li><i className="far fa-check"></i>Visa Category Expertise</li>
                                                <li><i className="far fa-check"></i>Transparency and Communication</li>
                                            </ul> */}
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="2" className="acc-btn">
                    How long will my visa be valid for?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="2" className="acc_body">
                    <div className="content">
                      <p>
                        The duration of your visa depends on factors such as the
                        visa officer's decision, your visa history, and your
                        bank statement.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="3" className="acc-btn">
                    How much is required in the bank statement?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="3" className="acc_body">
                    <div className="content">
                      <p>
                        It depends on your travel plan. If it's under 8 days,
                        £800 is required. For longer stays, it's an additional
                        £80 per day. The exact amount may also depend on the
                        specific country.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="4" className="acc-btn">
                    How much does a Schengen visa cost?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="4" className="acc_body">
                    <div className="content">
                      <p>
                        The appointment fee is around £30, plus an embassy fee
                        of approximately £70. Additionally, travel insurance
                        costs £10-£15p/a, return tickets range from £30 to £60,
                        and hotel costs will vary.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="5" className="acc-btn">
                    Can I use France issued visa to enter to another Schengen
                    country?<span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="5" className="acc_body">
                    <div className="content">
                      <p>
                        There is no general requirement that you must enter the
                        Schengen Area through the country that issued your visa.
                        Your main destination may be different than your first
                        destination. To prevent complications, stick to your
                        itinerary as best you can.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="6" className="acc-btn">
                    How many times can I apply for a Schengen Visa after
                    rejection?<span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="6" className="acc_body">
                    <div className="content">
                      <p>
                        You can make 1 appeal against your refusal of a Schengen
                        visa.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="7" className="acc-btn">
                    If a Schengen Visa is rejected, when can I apply again?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="7" className="acc_body">
                    <div className="content">
                      <p>
                        If your visa application is still not in your favor
                        after submitting an appeal, then you can reapply for a
                        Schengen visa. However, it is advised to wait for at
                        least 30 days before submitting your new visa
                        application.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem className="block">
                  <AccordionHeader targetId="8" className="acc-btn">
                    What is the difference between visa refusal and rejection?
                    <span className="arrow"></span>
                  </AccordionHeader>
                  <AccordionBody accordionId="8" className="acc_body">
                    <div className="content">
                      <p>
                        Visa refusal means when an applicant has done something
                        wrong and the application gets rejected by the embassy
                        consulate for entering another country. Whereas visa
                        rejection happens when an applicant fails to prove their
                        eligibility and submits missing documents, and has to
                        resubmit their paperwork.
                      </p>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FaqSection;
