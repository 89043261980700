import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ContactSection from "../../components/ContactSection";
import NewsLatter from "../../components/NewsLatter/NewsLatter";
import Footer from "../../components/footer/Footer";
import cimg1 from "../../images/country/flg_04.png";
import cimg2 from "../../images/country/uae.jpeg";
import cimg3 from "../../images/country/flg_03.png";

const ContactPage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Header />
      {/* <PageTitle pageTitle={"Contact"} pagesub={"Contact"} /> */}
      <section className="contact">
        <div className="container">
          <div className="row justify-content-md-center mt-none-30">
            <div className="col-lg-4 col-md-6 mt-30">
              <div className="xb-contact-info text-center">
                <div className="xb-item--flag">
                  <img src={cimg1} alt="" />
                </div>
                <h3 className="xb-item--title">
                  <Link onClick={ClickHandler} to="/country">
                    United Kingdom
                  </Link>
                </h3>
                <div className="xb-item--content">
                  {/* 17 Gedling Close, NN3 9UT, Northampton <br /> */}
                  +44 7947 478899 <br />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-30">
              <div className="xb-contact-info text-center">
                <div className="xb-item--flag">
                  <img src={cimg3} alt="" />
                </div>
                <h3 className="xb-item--title">
                  <Link onClick={ClickHandler} to="/country">
                    Australia
                  </Link>
                </h3>
                <div className="xb-item--content">Australia</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-30">
              <div className="xb-contact-info text-center">
                <div className="xb-item--flag">
                  <img src={cimg2} alt="" />
                </div>
                <h3 className="xb-item--title">
                  <Link onClick={ClickHandler} to="/country">
                    United Arab Emirates
                  </Link>
                </h3>
                <div className="xb-item--content">
                  Dubai
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <NewsLatter /> */}
      <ContactSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
