import React, { useState, useEffect } from "react";
import logo from '../images/comingSoon/logoManzilTravels.png';

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 60,
    hours: 23,
    minutes: 45,
    seconds: 30,
  });

  useEffect(() => {
    const decrementTime = () => {
      setTimeLeft(prevTime => {
        let { days, hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          minutes -= 1;
          seconds = 59;
        } else if (hours > 0) {
          hours -= 1;
          minutes = 59;
          seconds = 59;
        } else if (days > 0) {
          days -= 1;
          hours = 23;
          minutes = 59;
          seconds = 59;
        } else {
          clearInterval(intervalId);
        }

        return { days, hours, minutes, seconds };
      });
    };

    const intervalId = setInterval(decrementTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

//css
  const styles = {
    pageContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      minHeight: '100vh',
      backgroundColor: '#f0f0f0',
    },
    logo: {
      width: '50%',
      maxWidth: '300px',
      height: 'auto',
      marginBottom: '20px',
    },
    title: {
      fontSize: '2em',
      margin: '10px 0',
      color: '#333',
    },
    subTitle: {
      fontSize: '1.2em',
      margin: '10px 0',
      color: '#666',
    },
    counterListContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      margin: '20px 0',
      width: '100%',
    },
    counterItem: {
      flex: '1 1 20%',
      margin: '10px',
      padding: '10px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      maxWidth: '80px',
    },
    timeNumber: {
      fontSize: '1.8em', 
      color: '#102C57',   
      margin: '0',
    },
    timeUnit: {
      fontSize: '0.8em', 
      color: '#333',
      marginTop: '5px',
    },
  };

  return (
    <div style={styles.pageContent}>
      <img
        src={logo}
        alt="Manzil Consultancy Logo"
        style={styles.logo}
      />
      <p style={styles.title}>MANZIL CONSULTANCY</p>
      <p style={styles.subTitle}>
        We are working on it. Please contact us on WhatsApp for further queries.
      </p>

      <div style={styles.counterListContainer}>
        {timeLeft.days > 0 && (
          <div style={styles.counterItem}>
            <p style={styles.timeNumber} id="days">
              {timeLeft.days}
            </p>
            <p style={styles.timeUnit}>days</p>
          </div>
        )}
        <div style={styles.counterItem}>
          <p style={styles.timeNumber} id="hours">
            {timeLeft.hours}
          </p>
          <p style={styles.timeUnit}>hours</p>
        </div>
        <div style={styles.counterItem}>
          <p style={styles.timeNumber} id="minutes">
            {timeLeft.minutes}
          </p>
          <p style={styles.timeUnit}>minutes</p>
        </div>
        <div style={styles.counterItem}>
          <p style={styles.timeNumber} id="seconds">
            {timeLeft.seconds}
          </p>
          <p style={styles.timeUnit}>seconds</p>
        </div>
      </div>
      <p style={styles.subTitle}>Coming Soon</p>
    </div>
  );
};

export default ComingSoon;
