import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import shape from "../../images/icon/n_pad.svg";

const ContactSection = (props) => {
  return (
    // contact-pt i removed this class
    <section className="contact pt-20  pb-20 gray-bg">
      <div className="container">
        <div className="xb-contact pos-rel">
          <div className="row">
            <div className="col-lg-6">
              <div className="xb-item--inner">
                <div className="xb-item--holder mb-25">
                  <span>
                    <img src={shape} alt="" />
                    Contact Us
                  </span>
                  <h3>
                    Do you have any questions or want more <br /> information?
                  </h3>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="google-map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.824045308915!2d-0.8385294238995901!3d52.24657955643762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877090e765da4f9%3A0x9b42bd32609834d4!2s17%20Gedling%20Cl%2C%20Northampton%20NN3%209UT!5e0!3m2!1sen!2suk!4v1721653793328!5m2!1sen!2suk"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
