import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import icon1 from "../../images/icon/n_pad.svg";
import icon2 from "../../images/icon/time.svg";
import icon3 from "../../images/icon/location.svg";
import icon4 from "../../images/icon/search.svg";
import icon5 from "../../images/icon/us_flag.png";
import icon6 from "../../images/icon/arrow_down.svg";
import logo from "./logoManzilTravels.png";
// import logo from "../../images/logo/logo.svg";
import MobileMenu from "../MobileMenu/MobileMenu";
import logoManzil from "../../images/logo/manzilTravel.png";

import { FloatingWhatsApp } from "react-floating-whatsapp";

const Header = (props) => {
  const [searchActive, setSearchState] = useState(false);
  const [mobailActive, setMobailState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", zIndex: 99999 }}>
        <FloatingWhatsApp
          phoneNumber="+44 7947 478899"
          accountName="Manzil Consultancy"
          chatMessage={`Hey there ! I am your Visa Consultant. How May I help you ?`}
          notification
          // style={{ height: "100%" }}
          allowEsc
          avatar={logoManzil}
        />
      </div>
      <header className="site-header header-style-one">
        {/* <div className="header__top-wrap gray-bg">
        <div className="container" style={{marginTop:"-0.5em", marginBottom:"-0.5em"}}>
          <div className="header__top ul_li_between">
            <div className="header__top-cta">
              <img src={icon1} alt="" />
              <span>Help Desk :</span> +44 7947 478899
            </div>
            <ul className="header__top-info ul_li">
              <li>
                <img src={icon2} alt="" />
                Monday - Friday 09:00 am - 06:00 Pm
              </li>
              <li>
                <img src={icon3} alt="" />
                17 Gedling Close NN3 9UT Northampton, UK
              </li>
            </ul>
          </div>
        </div>
      </div> */}
     <div
  className={`header__wrap stricky ${isSticky ? "stricked-menu stricky-fixed" : ""}`}
>
  <div
    className="container"
    style={{
      maxHeight: "4.5em",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <div className="header__inner">
      {/* Logo on the left */}
      <div className="header__logo">
        <Link onClick={ClickHandler} to="/">
          <img
            height={100}
            width={100}
            src={logo}
            alt="Manzil Consultancy"
          />
        </Link>
      </div>

      {/* Navigation and other items on the right */}
      <div className="header__right">
        <div className="main-menu__wrap ul_li navbar navbar-expand-lg">
          <nav className="main-menu collapse navbar-collapse">
            <ul>
              <li className="active">
                <Link onClick={ClickHandler} to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="/">
                  <span>Services</span>
                </Link>
                <ul className="submenu">
                  <li>
                    <Link onClick={ClickHandler} to="/service-single/Visa Services">
                      <span>Visa Services</span>
                    </Link>
                    <Link onClick={ClickHandler} to="/service-single/Flight%20Tickets">
                      <span>Flight Tickets</span>
                    </Link>
                    <Link onClick={ClickHandler} to="/service-single/Hotel%20Bookings">
                      <span>Hotel Bookings</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/countries">
                  <span>Countries</span>
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/faq">
                  <span>FAQs</span>
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/about">
                  <span>About us</span>
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/contact">
                  <span>Contact Us</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="xb-hamburger-menu">
          <div className="xb-nav-mobile">
            <div
              className="xb-nav-mobile-button"
              onClick={() => setMobailState(!mobailActive)}
            >
              <i className="fal fa-bars"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        <div className="xb-header-wrap">
          <div className={`xb-header-menu ${mobailActive ? "active" : ""}`}>
            <div className="xb-header-menu-scroll">
              <div
                className="xb-menu-close xb-hide-xl xb-close"
                onClick={() => setMobailState(!mobailActive)}
              ></div>
              <div className="xb-logo-mobile xb-hide-xl">
                <Link onClick={ClickHandler} to="/" rel="home">
                  <img src={logo} alt="Manzil Consultancy" />
                  {/* <h5></h5> */}
                </Link>
              </div>
              <div className="xb-header-mobile-search xb-hide-xl">
                {/* <form role="search" onSubmit={SubmitHandler}>
                <input
                  type="text"
                  placeholder="Search..."
                  name="s"
                  className="search-field"
                />
                <button type="submit" className="search-submit"></button>
              </form> */}
              </div>
              <nav className="xb-header-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
          <div
            className="xb-header-menu-backdrop"
            onClick={() => setMobailState(false)}
          ></div>
        </div>

        <div
          className={`header-search-form-wrapper ${searchActive ? "open" : ""}`}
        >
          <div
            className="xb-search-close xb-close"
            onClick={() => setSearchState(!searchActive)}
          ></div>
          <div className="header-search-container">
            <form
              role="search"
              className="search-form"
              onSubmit={SubmitHandler}
            >
              <input
                type="search"
                className="search-field"
                placeholder="Search …"
                name="s"
              />
            </form>
          </div>
        </div>
        <div className={`body-overlay ${searchActive ? "active" : ""}`}></div>
      </header>
    </div>
  );
};

export default Header;
