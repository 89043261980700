import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import {
  CountryList,
  UAEList,
  EUlist,
  MiddleEastList,
  otherCountries,
} from "../../api/country";
import ServiceSidebar from "./sidebar";
import NewsLatter from "../../components/NewsLatter/NewsLatter";
import ContactSection from "../../components/ContactSection";
import Footer from "../../components/footer/Footer";
import eImg1 from "../../images/country/single.jpg";
import eImg from "../../images/icon/global.svg";
import eImg2 from "../../images/icon/profile-2user-2.svg";
import eImg3 from "../../images/icon/teacher.svg";
import eImg4 from "../../images/icon/home-trend-up.svg";
import eImg5 from "../../images/country/single2.jpg";
import eImg6 from "../../images/icon/star.svg";

const CountrySinglePage = (props) => {
  const { slug } = useParams();

  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
    textAlign: "center",
  };

  const thTdStyle = {
    border: "1px solid #102C57",
  };

  const theadStyle = {
    backgroundColor: "#102C57",
    color: "white",
  };

  const CountryDetails =
    CountryList.find((item) => item.slug === slug) ||
    UAEList.find((item) => item.slug === slug) ||
    MiddleEastList.find((item) => item.slug === slug) ||
    otherCountries.find((item) => item.slug === slug) ||
    EUlist.find((item) => item.slug === slug);

  return (
    <Fragment>
      <Header />
      <PageTitle pageTitle={CountryDetails.title} pagesub={"Country"} />
      <section className="coaching-single pt-120 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ServiceSidebar />
            </div>
            {/* For Australia */}
            {CountryDetails.title == "Australia" ? (
              <div className="col-lg-8">
                <div className="single-content">
                  <h3>The Australia</h3>
                  <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                  {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                  <div className="row align-items-center mt-10">
                    {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                    <div className="col-lg-12 mt-30">
                      <ul className="single-content-list list-unstyled pl-25">
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Original Signed Passport:</b>
                        </li>
                        <p>
                          Passport must have at least 2 blank pages for visa
                          purposes, must be valid for at least 90 days beyond
                          the return date of the trip to Australia and has to be
                          issued within the last 10 years on the day of leaving
                          Australia.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Passport-size photograph:</b>
                        </li>
                        <p>
                          Two recent (not older than 3 months), passport-size,
                          UK standard, colour photographs with white background
                          or you may also upload a photo with your order for us
                          to print (charges applicable).
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Proof of Status</b>
                        </li>
                        <p>
                          UK Residence permit, endorsed in the passport or
                          issued as a Biometrics card, must be valid for at
                          least 90 days beyond the return date of the trip to
                          Australia.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Confirmation of Employment: </b>
                        </li>
                        <p>
                          Employer headed letter (original – hand Signed) recent
                          (less than 10 days old), addressed to the Embassy,
                          name and position of the signee and confirming
                          applicant’s employment or provide latest 3 months
                          payslips.
                        </p>

                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Self Employment: </b>
                        </li>
                        <p>
                          Letter (original – hand Signed) from Accountant recent
                          (less than 10 days old), addressed to the Embassy and
                          confirming applicant’s self-employment and Certificate
                          of Incorporation/proof of annual accounts
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Student: </b>
                        </li>
                        <p>
                          Student letter (original - hand signed or with
                          original stamp) recent (less than 10 days old) signed
                          UK university/college/school letter (addressed to the
                          Embassy and confirming applicant’s enrolment)
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Domestic employees: </b>
                        </li>
                        <p>
                          Please provide an original letter of employment,
                          together with a copy of the employer’s passport and a
                          copy of the work contract
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Unemployed: </b>
                        </li>
                        <p>
                          Receiving Benefits/pension: recent original letter
                          obtained from local Job centre or Pension Service.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Bank Statements: </b>
                        </li>
                        <p>
                          Original last 3 months current account bank statements, certified by bank. with a minimum of $5000 per person regardless of the intended duration of the stay. The statement shouldn’t be older than 1 week.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Proof of travel: </b>
                        </li>
                        <p>
                          Confirmed return travel tickets mentioning applicant’s
                          name and travel dates.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Proof of accommodation: </b>
                        </li>
                        <p>
                          Copy of confirmed hotel reservations including name of
                          the applicant, travel dates and accommodation address
                          details.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Personal Invitation/Sponsor: </b>
                        </li>
                        <p>
                          If visiting friends or family, you must provide letter
                          of invitation with the contact information of the host
                          and visitor, who is responsible for the expenses,
                          purpose and duration of the visit, confirmation of
                          accommodation including the address, signature and
                          date. You will also need to provide proof of the
                          host's status in Australia i.e. copy of their
                          Australia passport's information page, or, if they are
                          not a citizen of Australia, copies of their Australia
                          residence permit and their national passport's
                          information pages. If you will be receiving financial
                          support from a host or other sponsor in Australia,
                          please provide a copy of that individual's bank
                          statement. Please note that the invitation letter from
                          your host should specifically state the types of
                          support they will be providing (financial,
                          accommodations, etc.).
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Personal Request Letter: </b>
                        </li>
                        <p>
                          {" "}
                          A personal letter from the applicant addressed to the
                          Embassy of Australia, explaining the purpose of the
                          trip, dates of travel, cities to be visited, and place
                          of accommodation. The letter must original and bear a
                          signature of the applicant.
                        </p>
                        <li style={{ marginBottom: 0 }}>
                          <img src={eImg6} alt="" />
                          <b>Travel insurance: </b>
                        </li>

                        <p>
                          (all-risk medical travel insurance) clearly showing
                          the applicant’s name, covering the entire period of
                          the intended stay in Australia. If covered by your
                          bank please provide a recent letter not older than 1
                          month confirming your insurance details.
                        </p>
                      </ul>
                      <div className="table-responsive">
                        <table style={tableStyle}>
                          <thead style={theadStyle}>
                            <tr>
                              <th style={thTdStyle}>Visa Category</th>
                              <th style={thTdStyle}>Type of Visa</th>
                              <th style={thTdStyle}>Validity</th>
                              <th style={thTdStyle}>Processing</th>
                              <th style={thTdStyle}>Embassy Fee</th>
                              <th style={thTdStyle}>Service Fee</th>
                              <th style={thTdStyle}>Total Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={thTdStyle}>Tourist (Standard)</td>
                              <td style={thTdStyle}>Single/Multiple entry</td>
                              <td style={thTdStyle}>6 months - 10 years</td>
                              <td style={thTdStyle}>2 - 4 weeks</td>
                              <td style={thTdStyle}>£ 250 - 750</td>
                              <td style={thTdStyle}>£ 250</td>
                              <td style={thTdStyle}>£ 500 - 1000</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <h3>Why choose us?</h3>
                  <div className="single-content__feature ul_li">
                    <div className="single-content-feature">
                      <div className="xb-item--inner ul_li">
                        <div className="xb-item--icon">
                          <img src={eImg} alt="" />
                        </div>
                        <h3 className="xb-item--title">
                          Supportive Environment
                        </h3>
                      </div>
                    </div>
                    <div className="single-content-feature">
                      <div className="xb-item--inner color-2 ul_li">
                        <div className="xb-item--icon">
                          <img src={eImg2} alt="" />
                        </div>
                        <h3 className="xb-item--title">
                          Student-Friendly Policies
                        </h3>
                      </div>
                    </div>
                    <div className="single-content-feature">
                      <div className="xb-item--inner color-3 ul_li">
                        <div className="xb-item--icon">
                          <img src={eImg3} alt="" />
                        </div>
                        <h3 className="xb-item--title">
                          Quality Higher Educations
                        </h3>
                      </div>
                    </div>
                    <div className="single-content-feature">
                      <div className="xb-item--inner color-4 ul_li">
                        <div className="xb-item--icon">
                          <img src={eImg4} alt="" />
                        </div>
                        <h3 className="xb-item--title">
                          Opportunities for Growth
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : //for UK
              CountryDetails.title == "Uk" ? (
                <div className="col-lg-8">
                  <div className="single-content">
                    <h3>United Kingdom</h3>
                    <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                    {/* <div className="single-img mt-35 mb-70">
                              <img src={eImg1} alt="" />
                            </div> */}
                    <div className="row align-items-center mt-10">
                      {/* <div className="col-lg-6 mt-30">
                                <img src={eImg5} alt="" />
                              </div> */}
                      <div className="col-lg-12 mt-30">
                        <ul className="single-content-list list-unstyled pl-25">
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>1.Original Signed Passport:</b>
                          </li>
                          <p>
                            Passport validity must be at least 6 months on the date of travel and have at least 2 blank pages.
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>2. Passport-size photograph:</b>
                          </li>
                          <p>
                          Two recent (not older than 3 months), passport-size, UK standard, color photographs with white background. You may also choose to upload
                           a photo to your order for us to print. There is a charge associated with this service.
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>3. Confirmation of Employment: </b>
                          </li>
                          <p>
                          Original letter from your employer/school on business letterhead (not older than one month) with contact details, stating that a leave of absence has been granted, purpose and duration of the trip, and that you will be returning to your current job. The Letter
                           must be addressed to the Embassy/High Commission, also provide last 6 months payslips.
                          </p>

                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>i. Self Employment: </b>
                          </li>
                          <p>
                          Letter from accountant confirming self-employment.
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>ii. Unemplyed: </b>
                          </li>
                          <p>
                          Sponsor’s last 3 months pay slips and 6 month 
                          bank statements. Sponsor’s copy of valid passport and declaration of financial support.
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>iii. Retired:  </b>
                          </li>
                          <p>
                            Please provide an original letter of employment,
                            together with a copy of the employer’s passport and a
                            copy of the work contract
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>4.	Hotel Reservations / Invitation letter from Family</b>
                          </li>
                          <p>
                          Copy of confirmed hotel reservations including name of the applicant,
                           name and address of the hotel, duration of stay or letter of invitation/accommodation.                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>5.	Bank statements: </b>
                          </li>
                          <p>
                          Original or copy of 6 
                          months’ bank statements. Financial ability for travel should show at least $10k 
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b>6.	Visa application form:  </b>
                          </li>
                          <p>
                            Please fill the form.
                          </p>
                          <li style={{ marginBottom: 0 }}>
                            <img src={eImg6} alt="" />
                            <b><h2>In case of sponsored trip</h2></b>
                          </li>
                          <p>
                          1.	Sponsorship letter duly dated and signed supported by the
                           following sponsor documents: passport (copy), employment letter,
                            last 6-month original bank statements. The documents shouldn’t be older than 1 month. Please be advised that the UK Embassy may extend the processing time at
                           their discretion. Additional documents and a personal interview may also be requested
                          </p>
                          <p>2.<b style={{ color: 'red' }}>	Note: Fingerprint and Photo (Biometric) Collection:</b>
                          The UK Embassy requires all visa applicants to submit fingerprints at designated facilities. Global Visa Agent offers dedicated and personalized fingerprint collection appointments and will schedule a convenient time for you after we have received your application in our office.
                          </p>
                        </ul>
                        <div className="table-responsive">
                          <table style={tableStyle}>
                            <thead style={theadStyle}>
                              <tr>
                                <th style={thTdStyle}>Visa Category</th>
                                <th style={thTdStyle}>Type of Visa</th>
                                <th style={thTdStyle}>Validity</th>
                                <th style={thTdStyle}>Processing</th>
                                <th style={thTdStyle}>Embassy Fee</th>
                                <th style={thTdStyle}>Service Fee</th>
                                <th style={thTdStyle}>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={thTdStyle}>Tourist (Standard)</td>
                                <td style={thTdStyle}>Single/Multiple entry</td>
                                <td style={thTdStyle}>upto 10 years</td>
                                <td style={thTdStyle}>6 - 8 weeks</td>
                                <td style={thTdStyle}>£ 115 - 965</td>
                                <td style={thTdStyle}>£ 285</td>
                                <td style={thTdStyle}>£ 400-1250</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <b style={{textAlign: "center"}}>Maximum stay in UK 180 days</b>
                      </div>
                    </div>

                    <h3>Why choose us?</h3>
                    <div className="single-content__feature ul_li">
                      <div className="single-content-feature">
                        <div className="xb-item--inner ul_li">
                          <div className="xb-item--icon">
                            <img src={eImg} alt="" />
                          </div>
                          <h3 className="xb-item--title">
                            Supportive Environment
                          </h3>
                        </div>
                      </div>
                      <div className="single-content-feature">
                        <div className="xb-item--inner color-2 ul_li">
                          <div className="xb-item--icon">
                            <img src={eImg2} alt="" />
                          </div>
                          <h3 className="xb-item--title">
                            Student-Friendly Policies
                          </h3>
                        </div>
                      </div>
                      <div className="single-content-feature">
                        <div className="xb-item--inner color-3 ul_li">
                          <div className="xb-item--icon">
                            <img src={eImg3} alt="" />
                          </div>
                          <h3 className="xb-item--title">
                            Quality Higher Educations
                          </h3>
                        </div>
                      </div>
                      <div className="single-content-feature">
                        <div className="xb-item--inner color-4 ul_li">
                          <div className="xb-item--icon">
                            <img src={eImg4} alt="" />
                          </div>
                          <h3 className="xb-item--title">
                            Opportunities for Growth
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) :
                // For Canada
                CountryDetails.title == "Canada" ? (
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>The Canada</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original Signed Passport:</b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to Australia and has to be
                              issued within the last 10 years on the day of leaving
                              Australia.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-type photograph: :</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, color photographs with white
                              background.You may also choose to upload a photo to
                              your order for us to print. There is a charge
                              associated with this service.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status:</b>
                            </li>
                            <p>
                              Original Residence card (BRP) or other proof of
                              resident status in the resident country should have
                              validity of more than 6 months after your return from
                              travel.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Copy of itinerary: </b>
                            </li>
                            <p>
                              Confirmed air tickets, must be showing exact dates of
                              travel and destination, however, it is advisable not
                              to purchase in advance.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Original letter from your employer/school on business
                              letterhead (not older than one month) with contact
                              details, stating that a leave of absence has been
                              granted, purpose and duration of the trip, and that
                              you will be returning to your current job. The Letter
                              must be addressed to the Embassy/High Commission, also
                              provide last 3 months payslips.
                            </p>
                            <ul className="single-content-list list-unstyled pl-25">
                              <li style={{ marginBottom: 0 }}>
                                <b>i. Self-employed:</b>{" "}
                              </li>
                              <p>
                                Letter from accountant confirming self-employment.
                              </p>
                              <li>
                                <b>ii. Unemployed: </b>
                              </li>
                              <p>
                                Sponsor’s last 3 months pay slips and bank
                                statements. Sponsor’s copy of valid passport and
                                declaration of financial support.
                              </p>
                              <li>
                                <b>iii. Retired: </b>
                              </li>
                              <p>
                                Please submit proof of your retirement/proof of
                                funds.
                              </p>
                            </ul>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Hotel Reservations / Invitation letter from
                                Family:{" "}
                              </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, name and address of the hotel, duration
                              of stay or letter of invitation/accommodation.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months current account bank
                              statements, certified by bank. with a minimum of $7000
                              per person regardless of the intended duration of the
                              stay. The statement shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Canada visa application form: </b>
                            </li>
                            <p>Please fill the form.</p>
                          </ul>
                          <h4 style={{ marginBottom: 0 }}>
                            In case of sponsership trip:
                          </h4>
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                1. Sponsorship letter duly dated and signed
                                supported by the following sponsor documents:
                              </b>
                            </li>
                            <p>
                              Passport (copy), employment letter, last 3-month
                              original bank statements. The documents shouldn’t be
                              older than 1 month. Please be advised that the
                              Canadian Embassy may extend the processing time at
                              their discretion. Additional documents and a personal
                              interview may also be requested. Canada Visa online
                              advises that you provide as many documents as you can
                              to prove beyond doubt that you will be returning back
                              after your trip to Canada.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b style={{ color: "red" }}>
                                2. Note: Fingerprint and Photo (Biometric)
                                Collection:
                              </b>
                            </li>
                            <p>
                              The Canada Embassy requires all visa applicants to
                              submit fingerprints at designated facilities. Global
                              Visa Agent offers dedicated and personalized
                              fingerprint collection appointments and will schedule
                              a convenient time for you after we have received your
                              application in our office.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Single/Multiple entry</td>
                                  <td style={thTdStyle}>6 months - 10 years</td>
                                  <td style={thTdStyle}>2-4 weeks</td>
                                  <td style={thTdStyle}>£ 115 - 965</td>
                                  <td style={thTdStyle}>£ 385</td>
                                  <td style={thTdStyle}>£ 500 - 1350</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in Canada: 180 days</p>
                        </div>
                      </div>

                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "USA" ? (
                  // for USA
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>USA</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original Signed Passport:</b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to Australia and has to be
                              issued within the last 10 years on the day of leaving
                              Australia.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-type photograph: :</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, color photographs with white
                              background.You may also choose to upload a photo to
                              your order for us to print. There is a charge
                              associated with this service.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status:</b>
                            </li>
                            <p>
                              Original Residence card (BRP) or other proof of
                              resident status in the resident country should have
                              validity of more than 6 months after your return from
                              travel.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Copy of itinerary: </b>
                            </li>
                            <p>
                              Confirmed air tickets, must be showing exact dates of
                              travel and destination, however, it is advisable not
                              to purchase in advance.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Original letter from your employer/school on business
                              letterhead (not older than one month) with contact
                              details, stating that a leave of absence has been
                              granted, purpose and duration of the trip, and that
                              you will be returning to your current job. The Letter
                              must be addressed to the Embassy/High Commission, also
                              provide last 3 months payslips.
                            </p>
                            <ul className="single-content-list list-unstyled pl-25">
                              <li style={{ marginBottom: 0 }}>
                                <b>i. Self-employed:</b>{" "}
                              </li>
                              <p>
                                Letter from accountant confirming self-employment.
                              </p>
                              <li>
                                <b>ii. Unemployed: </b>
                              </li>
                              <p>
                                Sponsor’s last 3 months pay slips and bank
                                statements. Sponsor’s copy of valid passport and
                                declaration of financial support.
                              </p>
                              <li>
                                <b>iii. Retired: </b>
                              </li>
                              <p>
                                Please submit proof of your retirement/proof of
                                funds.
                              </p>
                            </ul>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Hotel Reservations / Invitation letter from
                                Family:{" "}
                              </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, name and address of the hotel, duration
                              of stay or letter of invitation/accommodation.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months current account bank statements, certified by bank. with a minimum of $5000 per person regardless of the intended duration of the stay. The statement shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Canada visa application form: </b>
                            </li>
                            <p>Please fill the form.</p>
                          </ul>
                          <h4 style={{ marginBottom: 0 }}>
                            In case of sponsership trip:
                          </h4>
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                1. Sponsorship letter duly dated and signed
                                supported by the following sponsor documents:
                              </b>
                            </li>
                            <p>
                              Passport (copy), employment letter, last 3-month
                              original bank statements. The documents shouldn’t be
                              older than 1 month. Please be advised that the
                              Canadian Embassy may extend the processing time at
                              their discretion. Additional documents and a personal
                              interview may also be requested. Canada Visa online
                              advises that you provide as many documents as you can
                              to prove beyond doubt that you will be returning back
                              after your trip to Canada.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b style={{ color: "red" }}>
                                2. Note: Fingerprint and Photo (Biometric)
                                Collection:
                              </b>
                            </li>
                            <p>
                              The USA Embassy requires all visa applicants to
                              submit fingerprints at designated facilities. Global
                              Visa Agent offers dedicated and personalized
                              fingerprint collection appointments and will schedule
                              a convenient time for you after we have received your
                              application in our office.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Single/Multiple entry</td>
                                  <td style={thTdStyle}>5-10 years</td>
                                  <td style={thTdStyle}>2-4 Weeks</td>
                                  <td style={thTdStyle}>£ 160</td>
                                  <td style={thTdStyle}>£ 340</td>
                                  <td style={thTdStyle}>£ 500</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in USA: 180 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "China" ? (
                  // for CHINA
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>China</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original Signed Passport:</b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 6 months. For
                              passports issued from 2015, it is mandatory to provide
                              also the copies of the old/last passport.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-type photograph: :</b>
                            </li>
                            <p>
                              Two recent photographs should be in colour and of the
                              size of (33 mm x 48 mm).
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status:</b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, this should be valid for
                              more than 6 months after your return from China.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Proof of travel: </b>
                            </li>
                            <p>
                              Copy of itinerary showing exact dates of travel from
                              airline or travel agent. Please note that the number
                              of entries on your visa will be based upon your
                              itinerary.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Multiple entry letter: </b>
                            </li>
                            <p>
                              If multiple entries are desired, a detailed letter
                              explaining precisely why the multiple entry visa is
                              necessary. The decision to grant or deny the multiple
                              entry visas to China lies entirely with the China
                              Embassy.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Hotel Reservations / Invitation letter from
                                Family:{" "}
                              </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, name and address of the hotel, duration
                              of stay.
                            </p>
                            <b>OR</b>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Personal Invitation Letter </b>
                            </li>
                            <p>
                              If visiting friends or family, you must provide letter
                              of invitation from the host in China.{" "}
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Minor: </b>
                            </li>
                            <p>Contact us.</p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Dual nationals: </b>
                            </li>
                            <p>
                              Dual nationals must submit both original passports.
                              Applicants must apply using the passport that allows
                              them to reside in the UK: If one holds UK and EU
                              passports, he/she must use their UK passport
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Note </b>
                            </li>
                            <p>
                              Applicants over 70 years old do not need to submit
                              their biometrics.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Single entry</td>
                                  <td style={thTdStyle}>upto 3 months</td>
                                  <td style={thTdStyle}>4 business days</td>
                                  <td style={thTdStyle}>£ 94</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 244</td>
                                </tr>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Double entry</td>
                                  <td style={thTdStyle}>upto 6 months</td>
                                  <td style={thTdStyle}>4 business days</td>
                                  <td style={thTdStyle}>£ 108</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 258</td>
                                </tr>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Multiple entry</td>
                                  <td style={thTdStyle}>upto 6 months</td>
                                  <td style={thTdStyle}>4 business days</td>
                                  <td style={thTdStyle}>£ 122</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 272</td>
                                </tr>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Multiple entry</td>
                                  <td style={thTdStyle}>upto 1 year</td>
                                  <td style={thTdStyle}>4 business days</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 300</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in china: 90 Days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Dubai" ? (
                  // for Dubai
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Dubai</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Scanned copy of Passport:</b>
                            </li>
                            <p>
                              Scanned copy of Passport information page and signed
                              page, must have at least 1 blank page for visa
                              purposes, must be valid for at least 6 months beyond
                              the return date of the trip to the Dubai.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Scanned Copy of passport size photograph:</b>
                            </li>
                            <p>
                              Scanned Copy of passport size picture is required.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Scanned Proof of travel:</b>
                            </li>
                            <p>
                              Confirmed return travel tickets mentioning applicant’s
                              name and travel dates.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist (Standard)</td>
                                  <td style={thTdStyle}>Single entry</td>
                                  <td style={thTdStyle}>upto 14-30 days</td>
                                  <td style={thTdStyle}>5-7 business days</td>
                                  <td style={thTdStyle}>£ 105</td>
                                  <td style={thTdStyle}>£ 100</td>
                                  <td style={thTdStyle}>£ 155</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in Dubai: 90 Days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Saudia Arabia" ? (
                  // for Saudia
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Saudia Arabia</h3>
                      <h4 style={{ marginBottom: 0 }}>E-Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Scanned copy of Passport:</b>
                            </li>
                            <p>
                              Clear scan of Passport information page and signed
                              page, must have at least 1 blank page for visa
                              purposes, and must be valid for at least 6 months
                              beyond the return date of the trip to the Saudi.
                            </p>

                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Scanned Copy of passport size photograph:</b>
                            </li>
                            <p>Clear scan of recent digital photograph</p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>E-Tourist </td>
                                  <td style={thTdStyle}>Multiple entry</td>
                                  <td style={thTdStyle}>1 year</td>
                                  <td style={thTdStyle}>1 week</td>
                                  <td style={thTdStyle}>£ 125</td>
                                  <td style={thTdStyle}>£ 50</td>
                                  <td style={thTdStyle}>£ 175</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in Saudia : 30 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Ireland" ? (
                  // for Ireland
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Ireland</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Ireland
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Ireland.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips. 5. Self- employment: letter (original – hand
                              Signed) from Accountant recent (less than 10 days
                              old), addressed to the Embassy and confirming
                              applicant’s self-employment and Certificate of
                              Incorporation/proof of annual accounts.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Authorisation Letter:</b>
                            </li>
                            <p>
                              {" "}
                              An Original signed copy of the Irish Authorisation
                              Letter can be found here.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Application letter: </b>
                            </li>
                            <p>
                              Application letter addressed to Embassy of Ireland 17
                              Grosvenor Pl, Belgravia, London SW1X 7HR.Type or write
                              a letter explaining why you want to come to Ireland.
                              Sign and date the letter and submit it with your
                              documents. Your letter must include your full name and
                              postal address, and the:
                              <ol>
                                <li>1. Reason you want to come to Ireland.</li>
                                <li>2. Dates you plan to arrive and leave.</li>
                                <li>
                                  3. Names and addresses of any members of your
                                  family who currently live in Ireland or any other
                                  EU/EEA country or Switzerland.
                                </li>
                              </ol>
                              Your letter must include a commitment from you that
                              you will:
                              <ol>
                                <li>
                                  {" "}
                                  1. Obey the conditions of your visa in full
                                </li>
                                <li>
                                  2.Not rely on public services (e.g. public
                                  hospitals) or become a burden on the State.
                                </li>
                                <li>
                                  {" "}
                                  3. Leave Ireland before your immigration
                                  permission expires.
                                </li>
                              </ol>
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Bank statements: </b>
                            </li>
                            <p>
                              Original last 6 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>14. Please note: </b>
                            </li>
                            <p>
                              Each applicant must send us the Collection
                              Authorization Letter which can be downloaded by
                              clicking the 'Download and print' button above. When
                              applying for a Visa to Ireland please provide all
                              original documents; internet banking/Cash Point/ATM
                              Statements as the Evidence of Funds are not accepted.
                              Failure to do so may result in delays. The Irish
                              embassy has very particular processes and will not
                              accept an application until all the below documents
                              are provided in the originals. Multiple entry visa is
                              only possible if you have had previous visits to
                              Ireland.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist </td>
                                  <td style={thTdStyle}>Single/Multiple entry</td>
                                  <td style={thTdStyle}>1-12 months</td>
                                  <td style={thTdStyle}>1-2 weeks</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 300</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in Ireland : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Germany" ? (
                  // for Germany
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Germany</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. C type visitor visa is not
                              acceptable.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist </td>
                                  <td style={thTdStyle}>Single/Multiple entry</td>
                                  <td style={thTdStyle}>1-12 months</td>
                                  <td style={thTdStyle}>1-2 weeks</td>
                                  <td style={thTdStyle}>£ 115</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 265</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in Germany : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "France" ? (
                  // for France
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>France</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. C type visitor visa is not
                              acceptable.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist </td>
                                  <td style={thTdStyle}>Single/Multiple entry</td>
                                  <td style={thTdStyle}>1-12 months</td>
                                  <td style={thTdStyle}>1-2 weeks</td>
                                  <td style={thTdStyle}>£ 125</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 275</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay in France : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Netherland" ? (
                  // for Netherland
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Netherland</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. Short stay visa for the UK, a C
                              type visa, than applicant can also apply for a
                              Schengen visa; however the minimum processing time may
                              be considerably longer than regular 2-weeks minimum
                              processing time.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <table style={tableStyle}>
                            <thead style={theadStyle}>
                              <tr>
                                <th style={thTdStyle}>Visa Category</th>
                                <th style={thTdStyle}>Type of Visa</th>
                                <th style={thTdStyle}>Validity</th>
                                <th style={thTdStyle}>Processing</th>
                                <th style={thTdStyle}>Embassy Fee</th>
                                <th style={thTdStyle}>Service Fee</th>
                                <th style={thTdStyle}>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={thTdStyle}>Tourist </td>
                                <td style={thTdStyle}>Single/Multiple entry</td>
                                <td style={thTdStyle}>1-12 months</td>
                                <td style={thTdStyle}>1-2 weeks</td>
                                <td style={thTdStyle}>£ 125</td>
                                <td style={thTdStyle}>£ 150</td>
                                <td style={thTdStyle}>£ 265</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>Maximum stay in Netherland : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Italy" ? (
                  // for Italy
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Italy</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. Short stay visa for the UK, a C
                              type visa, than applicant can also apply for a
                              Schengen visa; however the minimum processing time may
                              be considerably longer than regular 2-weeks minimum
                              processing time.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <table style={tableStyle}>
                            <thead style={theadStyle}>
                              <tr>
                                <th style={thTdStyle}>Visa Category</th>
                                <th style={thTdStyle}>Type of Visa</th>
                                <th style={thTdStyle}>Validity</th>
                                <th style={thTdStyle}>Processing</th>
                                <th style={thTdStyle}>Embassy Fee</th>
                                <th style={thTdStyle}>Service Fee</th>
                                <th style={thTdStyle}>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={thTdStyle}>Tourist </td>
                                <td style={thTdStyle}>Single/Multiple entry</td>
                                <td style={thTdStyle}>1-12 months</td>
                                <td style={thTdStyle}>1-2 weeks</td>
                                <td style={thTdStyle}>£ 115</td>
                                <td style={thTdStyle}>£ 150</td>
                                <td style={thTdStyle}>£ 265</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>Maximum stay in Italy : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "spain" ? (
                  // for Spain
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Spain</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. Short stay visa for the UK, a C
                              type visa, than applicant can also apply for a
                              Schengen visa; however the minimum processing time may
                              be considerably longer than regular 2-weeks minimum
                              processing time.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <table style={tableStyle}>
                            <thead style={theadStyle}>
                              <tr>
                                <th style={thTdStyle}>Visa Category</th>
                                <th style={thTdStyle}>Type of Visa</th>
                                <th style={thTdStyle}>Validity</th>
                                <th style={thTdStyle}>Processing</th>
                                <th style={thTdStyle}>Embassy Fee</th>
                                <th style={thTdStyle}>Service Fee</th>
                                <th style={thTdStyle}>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={thTdStyle}>Tourist </td>
                                <td style={thTdStyle}>Single/Multiple entry</td>
                                <td style={thTdStyle}>1-12 months</td>
                                <td style={thTdStyle}>1-2 weeks</td>
                                <td style={thTdStyle}>£ 115</td>
                                <td style={thTdStyle}>£ 150</td>
                                <td style={thTdStyle}>£ 265</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>Maximum stay in Spain : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : CountryDetails.title == "Iraq" ||
                  "Iran" ||
                  "Kuwait" ||
                  "Oman" ||
                  "Saudi Arabia" ||
                  "Yemen" ||
                  "Qatar" ||
                  "Bahrain" ? (
                  "Please Contact Us on WhatsApp"
                ) : (
                  // SCHENGEN VISA
                  <div className="col-lg-8">
                    <div className="single-content">
                      <h3>Schengen Visa</h3>
                      <h4 style={{ marginBottom: 0 }}>Tourist Visa:</h4>
                      {/* <div className="single-img mt-35 mb-70">
                  <img src={eImg1} alt="" />
                </div> */}
                      <div className="row align-items-center mt-10">
                        {/* <div className="col-lg-6 mt-30">
                    <img src={eImg5} alt="" />
                  </div> */}
                        <div className="col-lg-12 mt-30">
                          <ul className="single-content-list list-unstyled pl-25">
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>1. Original, signed passport: </b>
                            </li>
                            <p>
                              Passport must have at least 2 blank pages for visa
                              purposes, must be valid for at least 90 days beyond
                              the return date of the trip to the Schengen Area and
                              has to be issued within the last 10 years on the day
                              of leaving the Schengen Area.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>2. Passport-size photograph:</b>
                            </li>
                            <p>
                              Two recent (not older than 3 months), passport-size,
                              UK standard, colour photographs with white background
                              or you may also upload a photo with your order for us
                              to print (charges applicable)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>3. Proof of Status: </b>
                            </li>
                            <p>
                              UK Residence permit, endorsed in the passport or
                              issued as a Biometrics card, must be valid for at
                              least 90 days beyond the return date of the trip to
                              the Schengen Area. Short stay visa for the UK, a C
                              type visa, than applicant can also apply for a
                              Schengen visa; however the minimum processing time may
                              be considerably longer than regular 2-weeks minimum
                              processing time.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>4. Confirmation of Employment: </b>
                            </li>
                            <p>
                              Employer headed letter (original – hand Signed) recent
                              (less than 10 days old), addressed to the Embassy,
                              name and position of the signee and confirming
                              applicant’s employment or provide latest 3 months
                              payslips.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>5. Self- employment: </b>
                            </li>
                            <p>
                              letter (original – hand Signed) from Accountant recent
                              (less than 10 days old), addressed to the Embassy and
                              confirming applicant’s self-employment and Certificate
                              of Incorporation/proof of annual accounts
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>
                                6. Student letter (original - hand signed or with
                                original stamp):
                              </b>
                            </li>
                            <p>
                              Recent (less than 10 days old) signed UK
                              university/college/school letter (addressed to the
                              Embassy and confirming applicant’s enrolment)
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>7. Domestic employees: </b>
                            </li>
                            <p>
                              Please provide an original letter of employment,
                              together with a copy of the employer’s passport and a
                              copy of the work contract
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>8. Unemployed - Receiving Benefits/pension: </b>
                            </li>
                            <p>
                              Recent original letter obtained from local Job centre
                              or Pension Service.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>9. Sponsorship</b>
                            </li>
                            <p>
                              Applicants may be sponsored by their spouse/registered
                              partner/any other relationship; in these cases, the
                              following needs to be provided: marriage certificate,
                              passport, proof of occupation, proof of funds and
                              cover letter. If any of these documents was issued
                              outside the EU, then translation is required in
                              English or country’ specific language. Note: The above
                              mentioned documents will not be accepted if they hold
                              a digital or scanned signature and if they are older
                              than 10 days.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>10. Bank statements: </b>
                            </li>
                            <p>
                              Original last 3 months UK current account bank
                              statements, certified by bank. £80 per day of stay and
                              person, with a minimum of £800 per person regardless
                              of the intended duration of the stay. The statement
                              shouldn’t be older than 1 week.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>11. Proof of travel:</b>
                            </li>
                            <p>
                              (airplane, train, boat, coach, (hired) car) Confirmed
                              return travel tickets mentioning applicant’s name and
                              travel dates. When travelling by car, car
                              registration, car insurance, return travel tickets
                              (ferry or Eurotunnel) mentioning the car’s
                              registration number and driver’s driving license. If
                              applicant is not the driver, then driver’s cover
                              letter (signed & dated) confirming joint travel with
                              applicant and travel dates need to be provided too.
                              When travel tickets have been paid through travel
                              agency, then a receipt, an invoice or a payment
                              confirmation is also required.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>12. Proof of accommodation: </b>
                            </li>
                            <p>
                              Copy of confirmed hotel reservations including name of
                              the applicant, travel dates and accommodation address
                              details.
                            </p>
                            <li style={{ marginBottom: 0 }}>
                              <img src={eImg6} alt="" />
                              <b>13. Travel insurance: </b>
                            </li>
                            <p>
                              (all-risk medical travel insurance) the travel
                              insurance from UK or German based Company – clearly
                              showing the applicant’s name, covering the entire
                              period of the intended stay in Schengen Area. The
                              minimum coverage for medical emergency and
                              repatriation must be € 30.000. If covered by your
                              bank, please provide a recent letter not older than 1
                              month confirming your insurance details. Note:
                              Personal Appearance and Biometric Appointment is
                              required.
                            </p>
                          </ul>
                          <div className="table-responsive">
                            <table style={tableStyle}>
                              <thead style={theadStyle}>
                                <tr>
                                  <th style={thTdStyle}>Visa Category</th>
                                  <th style={thTdStyle}>Type of Visa</th>
                                  <th style={thTdStyle}>Validity</th>
                                  <th style={thTdStyle}>Processing</th>
                                  <th style={thTdStyle}>Embassy Fee</th>
                                  <th style={thTdStyle}>Service Fee</th>
                                  <th style={thTdStyle}>Total Cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={thTdStyle}>Tourist( Standard) </td>
                                  <td style={thTdStyle}>Single/Multiple entries</td>
                                  <td style={thTdStyle}>1 Week -  5 Years</td>
                                  <td style={thTdStyle}>2 - 4 Weeks</td>
                                  <td style={thTdStyle}>£ 125</td>
                                  <td style={thTdStyle}>£ 150</td>
                                  <td style={thTdStyle}>£ 275</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p>Maximum stay : 90 days</p>
                        </div>
                      </div>
                      <h3>Why choose us?</h3>
                      <div className="single-content__feature ul_li">
                        <div className="single-content-feature">
                          <div className="xb-item--inner ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Supportive Environment
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-2 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg2} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Student-Friendly Policies
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-3 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg3} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Quality Higher Educations
                            </h3>
                          </div>
                        </div>
                        <div className="single-content-feature">
                          <div className="xb-item--inner color-4 ul_li">
                            <div className="xb-item--icon">
                              <img src={eImg4} alt="" />
                            </div>
                            <h3 className="xb-item--title">
                              Opportunities for Growth
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
          </div>
        </div>
      </section>
      {/* <NewsLatter /> */}
      <ContactSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default CountrySinglePage;
