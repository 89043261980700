import cnIcon1 from "../images/country/img_01.png";
import cnIcon2 from "../images/country/img_02.png";
import cnIcon3 from "../images/country/img_03.png";
import cnIcon4 from "../images/country/img_04.png";
import cnIcon5 from "../images/country/img_05.png";
import cnIcon6 from "../images/country/img_06.png";
import cnIcon7 from "../images/country/img_07.png";
import cnIcon8 from "../images/country/img_08.png";
import cnIcon9 from "../images/country/img_09.png";
import cnIcon10 from "../images/country/img_10.png";
import cnIcon11 from "../images/country/img_11.png";
import cnIcon12 from "../images/country/img_12.png";

import bigIcon1 from "../images/country/flg_01.png";
import bigIcon2 from "../images/country/flg_02.png";
import china from "../images/country/china.jpg";
import bigIcon3 from "../images/country/flg_03.png";
import bigIcon4 from "../images/country/flg_04.png";
import bigIcon5 from "../images/country/flg_05.png";
import bigIcon6 from "../images/country/flg_06.png";
import cyprus from "../images/country/cyprus1.jpeg";
import uae from "../images/country/uae.jpeg";
import saudia from "../images/country/saudia.jpeg";

import bahrain from "../images/country/bahrain.png";
import oman from "../images/country/oman.jpeg";
import yemen from "../images/country/yemen.jpeg";
import qatar from "../images/country/qatar.jpeg";
import kuwait from "../images/country/kuwait.jpeg";
import iraq from "../images/country/iraq.jpeg";
import iran from "../images/country/iran.jpeg";
import netherland from "../images/country/netherland.jpg";
import spain from "../images/country/spain.png";

export const MiddleEastList = [
  {
    Id: "1",
    sIcon: saudia,
    title: "Saudia Arabia",
    slug: "Saudia Arabia",
  },
  {
    Id: "2",
    sIcon: yemen,
    title: "Yemen",
    slug: "Yemen",
  },
  {
    Id: "3",
    sIcon: oman,
    title: "Oman",
    slug: "Oman",
  },
  {
    Id: "4",
    sIcon: bahrain,
    title: "Bahrain",
    slug: "Bahrain",
  },
  {
    Id: "5",
    sIcon: qatar,
    title: "Qatar",
    slug: "Qatar",
  },
  {
    Id: "6",
    sIcon: kuwait,
    title: "Kuwait",
    slug: "Kuwait",
  },
  {
    Id: "7",
    sIcon: iraq,
    title: "Iraq",
    slug: "Iraq",
  },
  {
    Id: "8",
    sIcon: iran,
    title: "Iran",
    slug: "Iran",
  },
];

export const UAEList = [
  {
    Id: "1",
    sIcon: uae,
    title: "Dubai",
    slug: "Dubai",
  },
];
export const EUlist = [
  {
    Id: "1",
    sIcon: cnIcon10,
    title: "Ireland",
    slug: "Ireland",
  },
  {
    Id: "2",
    sIcon: cyprus,
    title: "Cyprus",
    slug: "Cyprus",
  },
];
export const Branches = [
  {
    Id: "1",
    title: "United Kingdom",
    slug: "United Kingdom",
  },
  {
    Id: "2",
    title: "Australia",
    slug: "Australia",
  },
  {
    Id: "3",
    title: "Dubai",
    slug: "Dubai",
  },
];

export const otherCountries = [
  {
    Id: "1",
    sIcon: cnIcon4,
    title: "Australia",
    slug: "Australia",
  },
  {
    Id: "2",
    sIcon: bigIcon1,
    title: "Canada",
    slug: "Canada",
  },
  {
    Id: "3",
    sIcon: bigIcon2,
    title: "USA",
    slug: "USA",
  },
  {
    Id: "4",
    sIcon: bigIcon4,
    title: "Uk",
    slug: "Uk",
  },
  {
    Id: "5",
    sIcon: uae,
    title: "Dubai",
    slug: "Dubai",
  },
];

export const CountryList = [
  // {
  //   Id: "1",
  //   sIcon: cnIcon1,
  //   title: "Canada",
  //   slug: "Canada",
  // },
  {
    Id: "2",
    sIcon: cnIcon2,
    title: "Belgium",
    slug: "Belgium",
  },
  {
    Id: "3",
    sIcon: cnIcon3,
    title: "Denmark",
    slug: "Denmark",
  },
  // {
  //   Id: "4",
  //   sIcon: cnIcon4,
  //   title: "Australia",
  //   slug: "Australia",
  // },
  {
    Id: "5",
    sIcon: cnIcon5,
    title: "France",
    slug: "France",
  },
  {
    Id: "6",
    sIcon: cnIcon6,
    title: "Germany",
    slug: "Germany",
  },
  {
    Id: "7",
    sIcon: cnIcon7,
    title: "Greece",
    slug: "Greece",
  },
  {
    Id: "8",
    sIcon: cnIcon8,
    title: "Hungary",
    slug: "Hungary",
  },
  // {
  //   Id: "9",
  //   sIcon: cnIcon9,
  //   title: "Iceland",
  //   slug: "Iceland",
  // },
  // {
  //   Id: "10",
  //   sIcon: cnIcon10,
  //   title: "Ireland",
  //   slug: "Ireland",
  // },
  {
    Id: "11",
    sIcon: cnIcon11,
    title: "Italy",
    slug: "Italy",
  },
  {
    Id: "12",
    sIcon: cnIcon12,
    title: "Luxembourg",
    slug: "Luxembourg",
  },
  // {
  //   Id: "13",
  //   sIcon: cnIcon8,
  //   title: "Hungary",
  //   slug: "Hungary",
  // },
  {
    Id: "14",
    sIcon: cnIcon9,
    title: "Iceland",
    slug: "Iceland",
  },
  {
    Id: "15",
    sIcon: netherland,
    title: "netherland",
    slug: "netherland",
  },
  {
    Id: "23",
    sIcon: spain,

    title: "Spain",
    slug: "Spain",
  },
  // {
  //   Id: "16",
  //   sIcon: cnIcon11,
  //   title: "Italy",
  //   slug: "Italy",
  // },
  // {
  //   Id: "17",
  //   sIcon: cnIcon12,
  //   title: "Luxembourg",
  //   slug: "Luxembourg",
  // },
  // {
  //   Id: "18",
  //   sIcon: cnIcon4,
  //   title: "Australia",
  //   slug: "Australia",
  // },
  // {
  //   Id: "19",
  //   sIcon: bigIcon1,
  //   title: "Canada",
  //   slug: "Canada",
  // },
  // {
  //   Id: "20",
  //   sIcon: bigIcon2,
  //   title: "America",
  //   slug: "America",
  // },
  // {
  //   Id: "21",
  //   sIcon: bigIcon3,
  //   title: "Australia",
  //   slug: "Australia",
  // },
  //  {
  //    Id: "22",
  //    sIcon: bigIcon4,
  //    title: "UK",
  //    slug: "UK",
  //  },
];
