import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import StudentFunFact from '../../components/student-visa-components/StudentFunFact/StudentFunFact';
import Teams from '../../api/team'
import PartnerSection from '../../components/PartnerSection';
import ContactSection from '../../components/ContactSection';
// import aboutImg from '../../images/about/about_img3.jpg'
import aboutImg from '../../images/about/main.png'
import aboutImg2 from '../../images/about/values.jpeg'
import aboutImg3 from '../../images/about/mission.jpeg'
import Bg from '../../images/bg/team_bg.jpg'
import NewsLatter from '../../components/NewsLatter/NewsLatter';



const AboutUsPage = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
            <Header />
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <section className="about pt-130">
                <div className="container">
                    <div className="row mb-40 align-items-center">
                        <div className="col-lg-6">
                            <div className="sec-title mb-20">
                                <h2 className="wow skewIn">Committed to Your Visa <br /> <span>Success - About us</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="sec-title mb-20">
                                <p style={{textAlign:'justify'}}>Welcome to Manzil Consultancy, your trusted partner in navigating the world of visit visas. Established two years ago,
                                     our consultancy has quickly earned a reputation for excellence and reliability. We specialize in providing comprehensive
                                      visit visa services tailored to meet the unique needs of our clients. As we continue to grow, we are excited 
                                    to announce that additional visa options, including student visas, work visas, family visas, and permanent
                                     residency visas, will be coming soon.</p>
                            </div>
                        </div>
                    </div>
                    <div className="xb-about__img">
                        <img style={{width:"100vw"}} src={aboutImg} alt="" />
                    </div>
                </div>
            </section>
            <StudentFunFact />
            <section className="mission pt-130 pb-130">
                <div className="container">
                    <div className="row align-items-center flex-row-reverse mt-none-30">
                        <div className="col-lg-6 mt-30">
                            <div className="mission__img text-lg-end">
                                <div className="inner-img d-inline-block wow skewIn" data-wow-delay="200ms">
                                    <img src={aboutImg2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-30">
                            <div className="mission__content">
                                <div className="sec-title">
                                    <h2 className="mb-20 wow skewIn">Guiding Your Path with Our <br /> <span>Visit Visas</span></h2>
                                    <p>Making your travel dreams come true by facilitating visit visas to a wide range of destinations.
                                         Whether you're planning a vacation, visiting family and friends, 
                                        or exploring new cultures, we provide expert guidance and support to ensure a smooth and 
                                        successful visa application process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-100">
                        <div className="col-lg-6 mt-30">
                            <div className="mission__img">
                                <div className="inner-img d-inline-block wow skewIn">
                                    <img src={aboutImg3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-30">
                            <div className="mission__content">
                                <div className="sec-title">
                                    <h2 className="mb-20 wow skewIn" data-wow-delay="200ms">Our Mission <br /> <span>Service </span></h2>
                                    <p>At Manzil Consultancy, we understand that the visa application process can be daunting and overwhelming. Our mission is to 
                                        simplify this process and guide you every step of the way. Our team of experienced and knowledgeable 
                                        professionals is dedicated to ensuring that your visit visa application is handled with the utmost care and precision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="team pt-120 pb-130 bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="sec-title text-center mb-65">
                        <h2 className="mb-40 wow skewIn">Our trusted immigration <br/> <span> support team</span></h2>
                        <p>At the heart of our commitment to providing exceptional <br/> immigration solutions stands</p>
                    </div>
                    <div className="row mt-none-30">
                        {Teams.slice(0, 8).map((team, tm) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mt-30" key={tm}>
                                <div className="xb-team text-center">
                                    <div className="xb-item--inner" data-parallax='{"scale" : 1}'>
                                        <div className="xb-item--img">
                                            <img src={team.tImg} alt="" />
                                        </div>
                                        <div className="xb-item--holder">
                                            <h3 className="xb-item--name"><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link></h3>
                                            <span className="xb-item--designation">{team.title}</span>
                                        </div>
                                        <ul className="xb-item--social list-unstyled">
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}><i className="fab fa-facebook-f"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            <PartnerSection />
            {/* <NewsLatter /> */}
            <ContactSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutUsPage;
