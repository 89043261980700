import swIcon1 from '../images/icon/sv_01.svg'
import swIcon2 from '../images/icon/sv_02.svg'
import swIcon3 from '../images/icon/sv_03.svg'
import swIcon4 from '../images/icon/sv_04.svg'
import swIcon5 from '../images/icon/sv_05.svg'
import swIcon6 from '../images/icon/s_icon01.svg'
import swIcon7 from '../images/icon/s_icon02.svg'
import swIcon8 from '../images/icon/s_icon03.svg'
import swIcon9 from '../images/icon/s_icon04.svg'
import swIcon10 from '../images/icon/s_icon05.svg'
import swIcon11 from '../images/icon/s_icon06.svg'
import swIcon12 from '../images/icon/s_icon07.svg'
import swIcon13 from '../images/icon/s_icon08.svg'



export const subServices = [
    {
        Id: '1',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Visit Visa',
        slug: 'Visit Visa',
    },
    {
        Id: '1',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Student Visa',
        slug: 'Student Visa',
    },
    {
        Id: '2',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Work Visa',
        slug: 'Work Visa',
    },
    {
        Id: '3',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Pernanent Visa',
        slug: 'Permanent Visa',
    },
    {
        Id: '5',
        sIcon:swIcon2,
        iconColor:'color2',
        title: 'Business Visa',
        slug: 'Business Visa',
    },
   
   
   

]    
