import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import NewsLatter from "../../components/NewsLatter/NewsLatter";
import ContactSection from "../../components/ContactSection";
import Footer from "../../components/footer/Footer";
import Services from "../../api/service";
import ServiceSidebar from "./sidebar";
import {
  CountryList,
  EUlist,
  UAEList,
  MiddleEastList,
  otherCountries,
} from "../../api/country";
import { subServices } from "../../api/sub_services";
import ComingSoon from "../ComingSoon";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const ServiceSinglePage = (props) => {
  const { slug } = useParams();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  // Find the relevant service details
  const ServiceDetails = Services.find((item) => item.slug === slug);
  const SubServiceDetails = subServices.find((item) => item.slug === slug);

  // Determine the page title
  const pageTitle = ServiceDetails?.title || SubServiceDetails?.title || "Our Services";

  // Determine the content to display
  const renderContent = () => {
    if (ServiceDetails) {
      if (ServiceDetails.title === "Visa Services") {
        return (
          <>
            <h3>Manzil Visa Consultancy Visa Services</h3>
            <p>
              Welcome to Manzil Visa Consultancy! We specialize in providing a
              variety of visa services tailored to meet your unique travel and
              immigration needs. Currently, we offer Visit Visa services,
              ensuring a smooth and hassle-free process for your travel plans.
              Excitingly, we will soon be expanding our services to include Work
              Visa, Permanent Visa, Student Visa, and Business Visa options. At
              Manzil, your journey is our priority. Contact us today to learn
              more about how we can assist you with your Visit Visa and upcoming
              visa needs.
            </p>
          </>
        );
      }
    } else if (SubServiceDetails) {
      if (SubServiceDetails.title === "Visit Visa") {
        return (
          <div>
         
          <h3>Visit Visa</h3>
          <p>
            A tourist visa is your ticket to exploring new horizons and
            immersing yourself in the culture and beauty of a foreign land. This
            essential travel document grants you the freedom to embark on
            unforgettable adventures and create lasting memories, all while
            ensuring a smooth and hassle-free journey. With a tourist visa in
            hand, you can discover stunning landscapes, indulge in local
            cuisines, and experience the vibrant life of your destination, all
            without the worry of employment restrictions. Start your travel
            dreams with a tourist visa and unlock the wonders of the world!
          </p>
          </div>
        );
      }
    }
    return <ComingSoon />;
  };

  return (
    <Fragment>
      <Header />
      <PageTitle pageTitle={pageTitle} pagesub={"Project"} />
      <section className="coaching-single pt-120 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ServiceSidebar />
            </div>
            <div className="col-lg-8">
              <div className="single-content">
                {renderContent()}
                <div className="single-img">
                  {/* Countries Tab Navigation */}
                  <Nav
                    tabs
                    className="xb-country-nav nav nav-tabs ul_li_between mb-65"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => toggle("1")}
                      >
                        Top Countries
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => toggle("2")}
                      >
                        Schengen
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => toggle("3")}
                      >
                        EU Countries
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" })}
                        onClick={() => toggle("4")}
                      >
                        Middle East
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="xb-country ul_li">
                        {otherCountries.map((country, cnt) => (
                          <div className="xb-item--item" key={cnt}>
                            <Link
                              onClick={ClickHandler}
                              to={`/country-single/${country.slug}`}
                              className="xb-item--inner ul_li"
                            >
                              <div className="xb-item--flag">
                                <img src={country.sIcon} alt="" />
                              </div>
                              <h3 className="xb-item--title">
                                {country.title}
                              </h3>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="xb-country ul_li">
                        {CountryList.slice(0, 14).map((country, cnt) => (
                          <div className="xb-item--item" key={cnt}>
                            <Link
                              onClick={ClickHandler}
                              to={`/country-single/${country.slug}`}
                              className="xb-item--inner ul_li"
                            >
                              <div className="xb-item--flag">
                                <img src={country.sIcon} alt="" />
                              </div>
                              <h3 className="xb-item--title">
                                {country.title}
                              </h3>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="xb-country ul_li">
                        {EUlist.slice(0, 12).map((country, cnt) => (
                          <div className="xb-item--item" key={cnt}>
                            <Link
                              onClick={ClickHandler}
                              to={`/country-single/${country.slug}`}
                              className="xb-item--inner ul_li"
                            >
                              <div className="xb-item--flag">
                                <img src={country.sIcon} alt="" />
                              </div>
                              <h3 className="xb-item--title">
                                {country.title}
                              </h3>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div className="xb-country ul_li">
                        {MiddleEastList.slice(0, 16).map((country, cnt) => (
                          <div className="xb-item--item" key={cnt}>
                            <Link
                              onClick={ClickHandler}
                              to={`/country-single/${country.slug}`}
                              className="xb-item--inner ul_li"
                            >
                              <div className="xb-item--flag">
                                <img src={country.sIcon} alt="" />
                              </div>
                              <h3 className="xb-item--title">
                                {country.title}
                              </h3>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>

                <h3 className="mt-70">How Manzil Consultancy works</h3>
                <ul className="about-list ul_li list-unstyled">
                  <li>
                    <div className="xb-item--inner">
                      <div className="xb-item--number">1</div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title mb-10">
                          Choose your visa type
                        </h3>
                        <div className="xb-item--description">
                          Determine the Visa type for your travel
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="xb-item--inner">
                      <div className="xb-item--number color-2">2</div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title mb-10">Contact Us</h3>
                        <div className="xb-item--description">
                          Start your transaction by applying.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="xb-item--inner">
                      <div className="xb-item--number color-3">3</div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title mb-10">
                          Submit All Documents
                        </h3>
                        <div className="xb-item--description">
                          Collect all the required documents.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="xb-item--inner">
                      <div className="xb-item--number color-4">4</div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title mb-10">Get Your Visa</h3>
                        <div className="xb-item--description">
                          Receive your visa, which is finalized.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ServiceSinglePage;
