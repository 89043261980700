import React from "react";
import Services from "../../api/service";
import { Link } from "react-router-dom";
// import sImg from "../../images/service/img_01.jpg";
import sBg from "../../images/bg/service_bg.png";
import sImg from "../../images/manzilTravel/usa-passport.jpeg";

const ServiceSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="visa type pb-200">
      <div className="container">
        <div className="service-wrap pos-rel">
          <div className="service-img-wrap">
            <div
              className="service-bg"
              style={{ backgroundImage: `url(${sBg})` }}
            ></div>
            <div
              className="service-img"
              style={{ backgroundImage: `url(${sImg})` }}
            ></div>
          </div>
          <div className="sec-title wow skewIn pt-120">
            <h2 className="mb-60">
              Our Services<br /> <span></span>
            </h2>
          </div>
          <div className="row justify-content-md-center mt-none-30">
            {Services.slice(0, 4).map((service, srv) => (
              <div className={"col-lg-4 col-md-6 mt-10"} key={srv}>
                {service.title ? (
                  <Link
                  onClick={ClickHandler}
                  // to={service.slug=="Visa Services" ? `/service-single/${service.slug}`:'#'}
                  to={`/service-single/${service.slug}`}
                  // to='#'
                >
                  <div className="xb-service">
                    <div className="xb-item--inner">
                      <div
                        className={`xb-item--icon mb-50 ${service.iconColor}`}
                      >
                        <img src={service.sIcon} alt="" />
                      </div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title mb-20">
                          
                            {service.title}
                         
                        </h3>
                        <div className="xb-item--description">
                          {service.description}
                        </div>
                      </div>
                    </div>
                  </div>
                    </Link>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
