import React from 'react';
import Slider from "react-slick";
import logo from '../../images/manzilTravel/logoManzilTravels.png'
import rating from '../../images/icon/tr_rattig.png'
import Bg from '../../images/bg/tm_bg.png'


const testimonial = [
    {
      id: "01",
      Des: "I had an excellent experience with Manzil Visa Consultancy Services. The team was professional, and provided clear guidance throughout the process. Their personalized service and transparent pricing made me feel valued and confident. Thanks to their expertise, I can now travel with peace of mind. I highly recommend Global Visa Consultancy Services for reliable visa assistance.",
      Title: "Aneeqa Wajid",
    },
    {
      id: "02",
      Des: "I recently got my USA visit visa with the help of an exceptional consultancy. Their professional and knowledgeable team guided me through the process, ensuring all my documents were in order. Their personalized approach and clear communication made everything smooth and stress-free. I highly recommend their reliable and efficient visa assistance services.",
      Title: "Ijaz Ahmed",
    },
    {
      id: "03",
      Des: "I had a great experience getting my UK visit visa thanks to an excellent consultancy. Their expertise and clear guidance made the process smooth and hassle-free. The team's personalized attention and open communication were impressive. Highly recommend their reliable and efficient visa services.",
      Title: "Waqas Ahmed",
    },
    {
      id: "04",
      Des: "I recently obtained a 6-month multi-entry Schengen visa thanks to a fantastic consultancy. Their expertise and guidance made the process smooth and hassle-free. They meticulously handled my documents and offered outstanding support throughout. Their personalized approach and efficient service truly impressed me. I highly recommend this consultancy for anyone seeking reliable and effective visa assistance.",
      Title: "Anil Kumar",
    },
    {
      id: "05",
      Des: "I just got my Australian visit visa with the help of an amazing consultancy. They made the whole process incredibly smooth and stress-free. From clear instructions to making sure all my documents were in order, their attention to detail was spot on. Their personal touch and efficiency really stood out. I highly recommend them if you're looking for reliable and effective visa help.",
      Title: "MD June",
    },
  ];

const Testimonial = () => {

    const settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const slider = React.useRef(null);


    return (
        <section className="testimonial bg_img pt-50 pb-50" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="sec-title margin-none-md mb-30-xs mb-125">
                            <h2 className="mb-70 wow skewIn">Happy Clients Reflect on Their <br />
                                <span> Journey with Us</span></h2>
                            <p>Embark on a Global Exploration: <br /> Experience Joy in 190+ Countries <br /> and Across the
                                World</p>
                        </div>
                        <div className="xb-testimonial__nav ul_li">
                            <div className="tm-nav-item tm-button-prev" onClick={() => slider?.current?.slickPrev()}></div>
                            <div className="tm-nav-item tm-button-next" onClick={() => slider?.current?.slickNext()}></div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="xb-swiper-sliders">
                            <div className="xb-carousel-inner">
                                <div className="xb-testimonial-slider xb-swiper-container">
                                    <Slider ref={slider} {...settings}>
                                        {testimonial.map((testimonial, tsm) => (
                                            <div className="xb-testimonial" key={tsm}>
                                                <div className="xb-item--inner text-center">
                                                    <div className="xb-item--img">
                                                        <img style={{height:'100px'}} src={logo} alt="" />
                                                    </div>
                                                    <div className="xb-item--content">
                                                        "{testimonial.Des}"
                                                    </div>
                                                    <div className="xb-item--bottom">
                                                        <div className="xb-item--ratting">
                                                            <img src={rating} alt="" />
                                                        </div>
                                                        <h3 className="xb-item--title">{testimonial.Title}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;